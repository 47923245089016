// Dependencias
import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import * as Llamadas from './Llamadas';

const PrecioOfertas = () => {
  const PrecioOfertas = useRef();

  return (
    <div></div>
  )
};

class WebComponent extends HTMLElement {
	constructor() {
		super();
	}

	async connectedCallback() {
	
		var urlRoiback = await Llamadas.getConfiguracion("url-roiback-" + Liferay.ThemeDisplay.getLanguageId());
	
		//Pagina promociones
		var elementosBoton = document.querySelectorAll(".viva-hotel-card-actions, .offer-hotel-banner, .offers-and-promos-row .new_cards.card");
		for(var i = 0; i < elementosBoton.length; i++) {
			var codHotel = elementosBoton[i].querySelector("a.btn-viva, a.btn-adults").name;
			var precioHotel = await Llamadas.getPrecioHotel(codHotel);
			var elementoPrecio = elementosBoton[i].querySelector("span[name='precioOferta']");
			if(precioHotel && precioHotel.price && precioHotel.price != 0) {
				if(elementoPrecio) {
					var texto = elementoPrecio.textContent.replaceAll("\t", "").replaceAll("\n", "");
					if(texto == "") {
						var precioNoche = precioHotel.price / precioHotel.lengthOfStay;
						elementoPrecio.textContent = precioNoche.toFixed(0) + " " + precioHotel.currency;
					}
				}
				
				var fechaEntrada = new Date(precioHotel.checkIn);
				var fechaSalida = new Date(fechaEntrada);
				if(codHotel == "vanityhotel") {
					fechaSalida.setDate(fechaSalida.getDate() + 1);
				} else {
					fechaSalida.setDate(fechaSalida.getDate() + 2);
				}
				
				if(elementosBoton[i].querySelector("a.btn-viva, a.btn-adults") && (elementosBoton[i].querySelector("a.btn-viva, a.btn-adults").getAttribute("href") == "" || elementosBoton[i].querySelector("a.btn-viva, a.btn-adults").getAttribute("href") == "#")) {
					elementosBoton[i].querySelector("a.btn-viva, a.btn-adults").href = urlRoiback + "/bookcore/availability/" + codHotel + "/" + precioHotel.checkIn + "/" + 
						fechaSalida.getFullYear() + "-" + ((fechaSalida.getMonth() + 1 < 10) ? "0" + (fechaSalida.getMonth() + 1) : fechaSalida.getMonth() + 1) + "-" + ((fechaSalida.getDate() < 10) ? "0" + fechaSalida.getDate() : fechaSalida.getDate()) +
						"?adults=2";
				}
					
				var li = elementosBoton[i].querySelector("a.more-info");
				if(li) {
					var idModal = li.getAttribute("data-target");
					if(idModal) {
						var modalOferta = document.getElementById(idModal.replaceAll("#", ""));
						if(modalOferta) {
							var botonModal = modalOferta.querySelector("nav a.btn-reserve")
							if(botonModal) {
								botonModal.href = urlRoiback + "/bookcore/availability/" + codHotel + "/" + precioHotel.checkIn + "/" + 
									fechaSalida.getFullYear() + "-" + ((fechaSalida.getMonth() + 1 < 10) ? "0" + (fechaSalida.getMonth() + 1) : fechaSalida.getMonth() + 1) + "-" + ((fechaSalida.getDate() < 10) ? "0" + fechaSalida.getDate() : fechaSalida.getDate()) +
									"?adults=2";
							}
						}
					}
				}
			}
		}
		
		//Home
		var elementosBoton = document.querySelectorAll(".PromoSwiper .promoCard, .promoWrapper .new_cards.card");
		for(var i = 0; i < elementosBoton.length; i++) {
			var codHotel = elementosBoton[i].querySelector(".btnOfertasHome").name;
			var precioHotel = await Llamadas.getPrecioHotel(codHotel);
			var elementoPrecio = elementosBoton[i].querySelector("span[name='precioOferta']");
			if(precioHotel && precioHotel.price && precioHotel.price != 0) {
				if(elementoPrecio) {
					var texto = elementoPrecio.textContent.replaceAll("\t", "").replaceAll("\n", "");
					if(texto == "") {
						var precioNoche = precioHotel.price / precioHotel.lengthOfStay;
						elementoPrecio.textContent = precioNoche.toFixed(0) + " " + precioHotel.currency;
					}
				}
				
				var fechaEntrada = new Date(precioHotel.checkIn);
				var fechaSalida = new Date(fechaEntrada);
				if(codHotel == "vanityhotel") {
					fechaSalida.setDate(fechaSalida.getDate() + 1);
				} else {
					fechaSalida.setDate(fechaSalida.getDate() + 2);
				}
				
				if(elementosBoton[i].querySelector(".btnOfertasHome") && (elementosBoton[i].querySelector(".btnOfertasHome").getAttribute("href") == "" || elementosBoton[i].querySelector(".btnOfertasHome").getAttribute("href") == "#")) {
					elementosBoton[i].querySelector(".btnOfertasHome").href = urlRoiback + "/bookcore/availability/" + codHotel + "/" + precioHotel.checkIn + "/" + 
						fechaSalida.getFullYear() + "-" + ((fechaSalida.getMonth() + 1 < 10) ? "0" + (fechaSalida.getMonth() + 1) : fechaSalida.getMonth() + 1) + "-" + ((fechaSalida.getDate() < 10) ? "0" + fechaSalida.getDate() : fechaSalida.getDate()) +
						"?adults=2";
				}
			}
		}
		
				createRoot(this).render(
					<PrecioOfertas
					/>,
					this
				);
	}
}

const ELEMENT_ID = 'liferay-hoteles-viva-precio-ofertas';

if (!customElements.get(ELEMENT_ID)) {
	customElements.define(ELEMENT_ID, WebComponent);
}
