/**
 * SPDX-FileCopyrightText: (c) 2000 Liferay, Inc. https://liferay.com
 * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06
 */

import {Liferay} from './liferay.js';

const {REACT_APP_LIFERAY_HOST = window.location.origin} = process.env;

const baseFetch = async (url, options = {}) => {
	return Liferay.Util.fetch(REACT_APP_LIFERAY_HOST + '/' + url, {
		headers: {
			//'x-csrf-token': Liferay.authToken,
		},
		...options,
	});
};





async function getToken() {
	//Se crea un objeto con parámetros de url que se necesitan para dar autorización de la petición. Estos datos se configuran en el portal de Liferay.
	const bodyToken = new URLSearchParams({
		client_id: "id-43a561bf-6e6d-e92b-345a-ff04859dc28",
		client_secret: "secret-9b1cbf0f-c426-a69f-8563-10ff904f36",
		grant_type: "client_credentials"
	})

	//Se llama al servicio de token
	const responseToken =await callService('/o/oauth2/token', bodyToken, 'POST', 'application/x-www-form-urlencoded', '');
	var respuesta= responseToken.json();
	return respuesta;
}

function callService(url, body, method, contentType, authorization) {
	try {
		const response = "";
		if (method == 'GET' || method == 'HEAD') {
			return fetch(url, {
				method: method,
				headers: {
					"Content-Type": contentType,
					"Accept": "application/json",
					"Authorization": authorization
				}
			});
		} else {
			return fetch(url, {
				method: method,
				headers: {
					"Content-Type": contentType,
					"Accept": "application/json",
					"Authorization": authorization
				},
				body: body
			});
		}

		if (!response.ok) {
			throw new Error('Error al obtener los datos de ' + url);
		}

		const data = response;
		return data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}



export default baseFetch;
