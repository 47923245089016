import React from 'react';


import api from './api';
import {Liferay} from './liferay';

import CryptoES from 'crypto-es';

	export async function getPrecioHotel(codHotel){
		var url_servicio_roiback = await getConfiguracion("url-servicio-roiback");
		var api_key = await getConfiguracion("api-key-roiback");
		var api_secret = await getConfiguracion("api-secret-roiback");
		var timestamp = (Date.now() / 1000).toFixed(0);
		var signature = api_key + api_secret + timestamp;
		var sha512signature = CryptoES.SHA512(signature);
		var authorization = "ROIBACK APIKey=" + api_key + ",Signature=" + sha512signature + ",timestamp=" + timestamp;
		var marketCode = Liferay.ThemeDisplay.getBCP47LanguageId().split("-")[1];
		var todayDate = new Date();
		var todayDay = todayDate.getDate();
		var todayMonth = todayDate.getMonth() + 1;
		var todayYear = todayDate.getFullYear();
		var today = todayYear + "-" + ((todayMonth < 10) ? "0" + todayMonth : todayMonth) + "-" + ((todayDay < 10) ? "0" + todayDay : todayDay);
		var endRange = (todayYear + 1) + "-" + ((todayMonth < 10) ? "0" + todayMonth : todayMonth) + "-" + ((todayDay < 10) ? "0" + todayDay : todayDay);
		var resultado= await fetch(url_servicio_roiback + "/v0/price-cache/best-price-by-range/" + codHotel + "/wcorpo/" + marketCode + "/" + today + "/" + endRange, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': authorization
			}
		});
		//Hay que hacer text por el no-cors
		const string = await resultado.text();
		const json = string === "" ? {} : JSON.parse(string);
		return json;

	}
	
	export async function getConfiguracion(key) {
		var promesaUrl = await api('o/c/vivaconfiguracions/scopes/'+ Liferay.ThemeDisplay.getScopeGroupId() + '?fields=valor&filter=clave%20eq%20%27' + key + '%27')
		.catch((e) => console.error("Error en llamada url formulario: " + e));
		var final=await promesaUrl.json();
		var url= final.items[0].valor;
		return url; 
	}
	  
	  
export default Llamadas;